import axios from "axios";
import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { LanguageContext } from "../../context/LanguageContext";

const FilterGlobale = (props) => {
  const { lang, language } = useContext(LanguageContext);
  const typeStyle =
    props.typeStyle === "type-1"
      ? "row-cols-1"
      : props.typeStyle === "type-2"
      ? "row-cols-sm-2 row-cols-1"
      : "row-cols-md-4 row-cols-sm-2 row-cols-1";

  const typeStyleDiv =
    props.typeStyle === "type-1" || props.typeStyle === "type-2"
      ? ["col-12", "col-12"]
      : ["col-md-11", "col-md-1"];

  const [data, setData] = useState({
    cycle: [],
    niveau: [],
    matiere: [],
    ville: [],
  });
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    cycle: props.cycle ?? { value: "all", label: lang("all") },
    niveau: props.niveau ?? null,
    matiere: props.matiere ?? null,
    ville: props.ville ?? null,
  });

  const fetchData = async (url, transformer = (item) => item) => {
    try {
      const response = await axios.get(url);
      return response.data.data.map(transformer);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching data. Please try again.");
      return [];
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [cycleData, villeData, niveauData] = await Promise.all([
          fetchData("/api/cycle", (item) => ({
            value: item.id,
            label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
          })),
          fetchData("/api/ville/149", (item) => ({
            value: item.id,
            label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
          })),
          fetchData("/api/niveaux", (item) => ({
            value: item.id,
            label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
            ids: item.ids,
          })),
        ]);

        setData({
          cycle: [{ value: "all", label: lang("all") }, ...cycleData],
          niveau: niveauData,
          ville: villeData,
        });

        if (props.niveau) {
          console.log("niveauData is already" + niveauData);
          let niveau = niveauData.find(
            (niveau) => niveau.ids && niveau.ids.includes(props.niveau)
          );
          console.log("find:" + niveau);

          if (niveau) {
            handleNiveauChange(niveau);
          }
        }

        setError(null);
      } catch {
        setError("Error loading initial data. Please try again.");
      }
    };

    loadInitialData();
  }, [lang, language]);

  const handleCycleChange = async (selectedCycle) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      cycle: selectedCycle,
      niveau: null,
      matiere: null,
    }));
    try {
      const endpoint =
        selectedCycle.value === "all"
          ? `/api/niveaux`
          : `/api/niveaux/${selectedCycle.value}`;
      const niveauData = await fetchData(endpoint, (item) => ({
        value: item.id,
        label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
      }));
      setData((prevData) => ({ ...prevData, niveau: niveauData, matiere: [] }));
      setError(null);
    } catch (error) {
      console.error("Error fetching niveau data:", error);
      setError("Error fetching niveau data. Please try again.");
    }
  };

  const handleNiveauChange = async (selectedNiveau) => {
    try {
      const endpoint = `/api/matiere/${selectedNiveau.value}/${selectedFilters.cycle.value}`;
      console.log("endpoint:" + endpoint);

      const matiereData = await fetchData(endpoint, (item) => ({
        value: item.id,
        label: item.lang ? JSON.parse(item.lang)[language] : item.nom,
      }));
      setData((prevData) => ({ ...prevData, matiere: matiereData }));

      let matiere = [];
      if (props.matiere) {
        matiere = matiereData.filter(
          (matiere) => matiere.value === props.matiere
        );
      }
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        niveau: selectedNiveau,
        matiere: matiere.length > 0 ? matiere[0] : null,
      }));
      console.log("matiere:", matiere);

      setError(null);
    } catch (error) {
      console.error("Error fetching matiere data:", error);
      setError("Error fetching matiere data. Please try again.");
    }
  };

  const handleOtherChange = (field) => (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [field]: selectedOption,
    }));
  };

  const send = () => {
    const { cycle, niveau, matiere, ville } = selectedFilters;
    if (cycle && niveau && matiere && ville) {
      props.onClick(selectedFilters);
      setError(null);
    } else {
      setError("Please select all options.");
    }
  };

  return (
    <div className="row align-items-center justify-content-center g-2">
      <div className="col-12 text-center">
        {error && <b className="text-warning">{error}</b>}
      </div>
      <div className={typeStyleDiv[0]}>
        <div
          className={
            "row " +
            typeStyle +
            " align-items-center justify-content-center g-1"
          }
        >
          <div className="col">
            <Select
              onChange={handleCycleChange}
              placeholder={lang("domain")}
              inputId="Domain"
              isLoading={data.cycle.length === 0}
              value={selectedFilters.cycle}
              options={data.cycle}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          <div className="col">
            <Select
              onChange={handleNiveauChange}
              placeholder={lang("speciality")}
              inputId="Specialization"
              isLoading={!data.niveau}
              value={selectedFilters.niveau}
              options={data.niveau || []}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          <div className="col">
            <Select
              onChange={handleOtherChange("matiere")}
              placeholder={lang("service")}
              inputId="Service"
              isLoading={!data.matiere}
              value={selectedFilters.matiere}
              options={data.matiere || []}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
          <div className="col">
            <Select
              onChange={handleOtherChange("ville")}
              placeholder={lang("city")}
              inputId="Ville"
              isLoading={!data.ville}
              value={selectedFilters.ville}
              options={data.ville || []}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        </div>
      </div>
      <div className={typeStyleDiv[1] + " text-center"}>
        <button
          type="button"
          onClick={send}
          className="lab-btn lab-btn-text px-4 py-1 rounded"
          style={{ backgroundColor: "#26c976" }}
        >
          <i className="icofont-search fw-bold text-white fs-5"></i>
        </button>
      </div>
    </div>
  );
};

export default FilterGlobale;
