import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";

import PageHeader from "../component/layout/pageheader";
import { LanguageContext } from "../context/LanguageContext";
import FilterGlobale from "../component/element/filterGlobale";

const ChatAi = () => {
  const { lang, language } = useContext(LanguageContext);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("m");
  const sp = queryParams.get("sp");
  const sv = queryParams.get("sv");

  const [selectedFilters, setSelectedFilters] = useState({
    cycle: { value: "all", label: lang("all") },
    niveau: parseInt(sp) ?? null,
    matiere: parseInt(sv) ?? null,
    ville: null,
  });

  const openFilter = (selectedFilters) => {
    navigate("/experts", {
      state: {
        selectedFilters,
      },
    });
  };

  return (
    <Fragment>
      <PageHeader
        title={"Votre solution commence avec l'IA, Se complète avec l'humain."}
        curPage={"Chat Ai"}
      />
      <div className="padding-tb section-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img className="w-100 h-100" src="/assets/images/about/01.png" />
            </div>
            <div className="col-md-6">
              <h2 className="title">
                Obtenez une Analyse Approfondie avec un Expert
              </h2>
              <p>
                Notre assistant intelligent a déjà fourni une première réponse à
                votre question.
                <br /> Pour aller plus loin et obtenir des conseils spécialisés
                adaptés à votre situation, nous vous invitons à consulter un
                expert de notre équipe.
                <br /> Renseignez votre ville et ajustez les filtres pour
                affiner votre demande, et découvrez comment notre expertise peut
                vous aider de manière personnalisée.
              </p>
              <div className="my-3">
                <textarea defaultValue={message}></textarea>
                <FilterGlobale
                  cycle={selectedFilters.cycle}
                  niveau={selectedFilters.niveau}
                  matiere={selectedFilters.matiere}
                  ville={selectedFilters.ville}
                  onClick={(value) => openFilter(value)}
                  typeStyle="type-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatAi;
