import { Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Agenda from "../component/element/agenda";
import { LanguageContext } from "../context/LanguageContext";

const CourseSingle = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { lang, dir, language } = useContext(LanguageContext);

  const [user, setUser] = useState(null);

  const fetchData = () => {
    var link = `/api/user/${params.id}/${language}`;
    console.log(link);
    axios
      .get(link)
      .then(function (response) {
        setUser(response.data);
        console.log("valid :)", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const goToCartPage = () => {
    navigate(`/booking/${params.id}`);
  };

  const selectTime = (value) => {};

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div dir={dir}>
      {user ? (
        <>
          <div
            className="pageheader-section-2 course-single-section section-bg"
            style={{ backgroundImage: "" }}
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div
                    className="authors mt-0"
                    style={{ boxShadow: "0px 0px 11px 1px rgb(255 0 0 / 38%)" }}
                    dir="ltr"
                  >
                    <div className="author-thumb">
                      <img
                        src={user.profile_photo_path}
                        alt="profile photo"
                        style={{ borderRadius: "3%" }}
                      />
                    </div>
                    <div
                      className={`author-content  ${
                        dir === "rtl" ? "text-end" : "text-start"
                      }`}
                      dir={dir}
                    >
                      <h4>{user.titre}</h4>
                      <h6 className="m-0">
                        {user.first_name + " " + user.last_name}
                        <span className="mx-2 badge bg-success fs-6">
                          {user.cycle}
                        </span>
                      </h6>
                      <div className="row row-cols-1 row-cols-md-2">
                        <div className="col">
                          {lang("ville")} : {user.city}
                        </div>
                        <div className="col">
                          {lang("langues")} :
                          {user.langueEns.map((langue, i) => (
                            <div
                              key={i}
                              className={`me-1 fw-bold badge bg-success rounded-pill`}
                            >
                              {user.LangueMaternal === langue.langue ? (
                                <u title="Langue maternelle">{langue.langue}</u>
                              ) : (
                                <>{langue.langue}</>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="col">
                          {lang("situation_actuelle")} :
                          {user.situationActualles}
                        </div>
                        <div className="col">
                          {lang("annees_d_expérience")} :{user.Annee_experience}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center my-3">
                    <button
                      className="lab-btn text-white"
                      onClick={goToCartPage}
                    >
                      {lang("consulter_maintenant")}
                    </button>
                  </div>

                  <div className="main-part">
                    <style>
                      {`.accordion-button::after{
                            margin-left: 0;
                            margin-top: 10px;
                        }`}
                    </style>
                    <div className="course-video">
                      <div className="course-video-content card card-title p-3">
                        <div className="course-video-title">
                          <h4>{lang("specialisations")}</h4>
                        </div>
                        <div
                          dir="ltr"
                          className="accordion"
                          id="accordionExample"
                        >
                          {user.cours.map((cours, i) => (
                            <div key={i} className="accordion-item">
                              <h2
                                className="accordion-header"
                                id={`accordion${i}`}
                              >
                                <div
                                  className="row justify-content-center accordion-button collapsed px-2 bg-success text-white mb-1"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#videoList${i}`}
                                  aria-expanded="false"
                                  aria-controls={`videoList${i}`}
                                  role="button"
                                >
                                  <div className="col-12 text-center">
                                    {cours.niveau}: {cours.matier}
                                  </div>
                                </div>
                              </h2>
                              <div
                                key={i}
                                id={`videoList${i}`}
                                className="accordion-collapse collapse "
                                aria-labelledby={`accordion${i}`}
                              >
                                <ul key={i} className="list-group">
                                  {cours.programme.map((prog, k) => (
                                    <li
                                      key={`${i}_${prog.id}`}
                                      className="list-group-item"
                                    >
                                      <div className="video-item-title text-center">
                                        {prog.nom}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Type Rencontre */}
                    <div className="card my-3 card-body">
                      <h5 className="card-title">
                        <i className="icofont-price"></i>
                        {lang("typeRencontre")}
                      </h5>
                      <div className="row g-3 justify-content-center">
                        {user.rencontre.map((val, i) => (
                          <div className="col-md-4 fs-5 fw-bold" key={i}>
                            <div className="d-flex justify-content-between border border-2 border-black py-3 px-2 rounded">
                              <span>
                                <i
                                  className={`${val.rencontreIcon} text-primary pe-2`}
                                ></i>
                                {val.rencontre}
                              </span>
                              <span className="ms-2 text-success">{`${
                                val.price + " " + user.currency + "/45min"
                              }`}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Agenda */}
                    <Agenda
                      dispo={user.dispo}
                      onclickTime={(value) => selectTime(value)}
                    />
                    <div className="text-center my-3">
                      <button
                        className="lab-btn text-white"
                        onClick={goToCartPage}
                      >
                        {lang("consulter_maintenant")}
                      </button>
                    </div>

                    <div className="course-item">
                      <div className="course-inner">
                        <div
                          className="card card-body rounded"
                          style={{ textAlign: "justify" }}
                        >
                          <h4>{lang("presentation")}</h4>
                          <p>{user.presentation}</p>
                          <h4>{lang("experience")}</h4>
                          <p>{user.experience}</p>
                          <h4>{lang("motivate")}</h4>
                          <p>{user.motivate}</p>
                        </div>
                      </div>
                    </div>

                    {/* formation */}
                    {user.education && user.education.length > 0 && (
                      <div className="card card-body mt-3">
                        <h4>{lang("formationEducation")}</h4>
                        <div className="mt-2">
                          <div className="card px-3 py-2">
                            {user.education.map((e, id) => (
                              <div key={id} className="row gy-3">
                                <div key={id} className="col-2 text-center">
                                  {e.date}
                                </div>
                                <div className="col-10">
                                  <p className="fs-5 m-0">{e.specialite}</p>
                                  <p className="fs-6 m-0">
                                    {e.niveau} - {e.ecole}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center my-3">
              <button className="lab-btn text-white" onClick={goToCartPage}>
                {lang("consulter_maintenant")}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center  position-absolute top-50 start-50 translate-middle">
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseSingle;
