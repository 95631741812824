import { Fragment } from "react";
import LoginSignup from "../component/element/loginSignup";
import PageHeader from "../component/layout/pageheader";

const LoginsignupPage = (param) => {
  return (
    <Fragment>
      <PageHeader title={"Page"} curPage={"Login"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <LoginSignup type={param.type} />
        </div>
      </div>
    </Fragment>
  );
};

export default LoginsignupPage;
