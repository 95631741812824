import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import BannerFour from "../component/section/banner-4";
/* import CategoryThree from "../component/section/category-3";*/
import ClientsTwo from "../component/section/clients-2";
import FeatureTwo from "../component/section/feature-2";
import About from "../component/section/about";
/* import Category from "../component/section/category";*/
import InstructorThree from "../component/section/instructor-3";
import AboutTwo from "../component/section/about-2";
import AboutTwo2 from "../component/section/about-22";
import { LanguageContext } from "../context/LanguageContext";

const HomeFour = () => {
  const location = useLocation();
  const {dir} = useContext(LanguageContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get("scroll");
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);

  return (
    <div dir={dir}>
      <BannerFour />
      <AboutTwo2 />
      <AboutTwo /> {/* comment_reserver */}
      <InstructorThree /> {/* Nos experts */}
      <About /> {/* nos services */}
      <FeatureTwo />
      <ClientsTwo />
    </div>
  );
};

export default HomeFour;
