import { Fragment, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../component/layout/pageheader";
import FilterGlobale from "../component/element/filterGlobale";
import FilterSecondary from "../component/element/filterSecondary";
import CardProvider from "../component/element/cardProvider";
import axios from "axios";
import { LanguageContext } from "../context/LanguageContext";
import FooterThree from "../component/layout/footer-3";

const CoursePage = () => {
  const location = useLocation();
  const { lang } = useContext(LanguageContext);

  const [selectedFilters, setSelectedFilters] = useState({
    cycle: location?.state?.selectedFilters?.cycle ?? { value: "all", label: lang("all") },
    niveau: location?.state?.selectedFilters?.niveau ?? null,
    matiere: location?.state?.selectedFilters?.matiere ?? null,
    ville: location?.state?.selectedFilters?.ville ?? null,
    typeRencontre: location?.state?.selectedFilters?.typeRencontre ?? null,
  });

  const [selectedFiltersSecondary, setSelectedFiltersSecondary] = useState({
    genre: { value: 0, label: lang("all") },
    price: null,
    langueEn: null,
    typeRencontre: null,
  });

  const [data, setDatas] = useState([]);
  const [page, setPage] = useState("?page=1");
  const [prof, setProf] = useState([]);
  const [isLoading, setIsloading] = useState({
    status: false,
    message: "",
  });

  const PageFn = (value) => {
    setPage(value);
    window.scrollTo({
      top: 350,
      left: 0,
      behavior: "smooth",
    });
  };

  const fetchData = () => {
    setIsloading((prevFilters) => ({
      ...prevFilters,
      status: true,
      message: lang("messageLoading"),
    }));
    var link = "/api/annonces" + page.replace("/", "");

    axios.get("/sanctum/csrf-cookie").then((res) => {
      axios
        .post(link, {
          cycle: selectedFilters.cycle.value,
          matiere: selectedFilters.matiere.value,
          niveaux: selectedFilters.niveau.value,
          ville: selectedFilters.ville.label,
          genre: selectedFiltersSecondary.genre.value,
          price: selectedFiltersSecondary.price,
          typeRenc:
            selectedFiltersSecondary.typeRencontre
              ?.map((rencontre) => rencontre.value)
              .join(",") ?? null,
          langueEn:
            selectedFiltersSecondary.langueEn
              ?.map((langue) => langue.value)
              .join(",") ?? null,
        })
        .then((response) => {
          setDatas(response.data);
          setIsloading((prevFilters) => ({
            ...prevFilters,
            status: false,
            message: "",
          }));
          console.log("response data", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  useEffect(() => {
    /* selectedFilters.typeRencontre && */
    if (
      selectedFilters.matiere &&
      selectedFilters.ville &&
      selectedFilters.niveau
    ) {
      setProf([]);
      fetchData();
    }
  }, [selectedFilters, selectedFiltersSecondary, page]);

  useEffect(() => {
    if (page === "?page=1") {
      console.log("data ======> yes ", data.data);
      setProf(data.data);
    } else {
      var arraypush = [];
      var array = Object.entries(data.data || {});
      array.forEach(([key, value]) => {
        return arraypush.push(value);
      });
      console.log("data ======> Non ", arraypush);
      setProf(arraypush);
    }
  }, [data]);

  const submit = (value) => {
    if (value.cycle !== selectedFilters.cycle) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        cycle: value.cycle,
      }));
    }
    if (value.niveau !== selectedFilters.niveau) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        niveau: value.niveau,
      }));
    }
    if (value.matiere !== selectedFilters.matiere) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        matiere: value.matiere,
      }));
    }
    if (value.ville !== selectedFilters.ville) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        ville: value.ville,
      }));
    }
  };

  const saveDataFilterSecondary = (value) => {
    if (value.genre !== selectedFiltersSecondary.genre) {
      setSelectedFiltersSecondary((prevFilters) => ({
        ...prevFilters,
        genre: value.genre,
      }));
    }
    if (value.price !== selectedFiltersSecondary.price) {
      setSelectedFiltersSecondary((prevFilters) => ({
        ...prevFilters,
        price: value.price,
      }));
    }
    if (value.langueEn !== selectedFiltersSecondary.langueEn) {
      setSelectedFiltersSecondary((prevFilters) => ({
        ...prevFilters,
        langueEn: value.langueEn,
      }));
    }
    if (value.typeRencontre !== selectedFiltersSecondary.typeRencontre) {
      setSelectedFiltersSecondary((prevFilters) => ({
        ...prevFilters,
        typeRencontre: value.typeRencontre,
      }));
    }
  };

  return (
    <Fragment>
      <PageHeader title={lang("experts_title")} curPage={"Course Page"} />
      <div className="group-select-section">
        <div className="container">
          <div className="section-wrapper">
            <div className="row align-items-center g-4">
              <div className="col-md-12">
                <div className="group-select-right">
                  <FilterGlobale
                    cycle={selectedFilters.cycle}
                    matiere={selectedFilters.matiere?.value}
                    ville={selectedFilters.ville}
                    niveau={selectedFilters.niveau?.value}
                    onClick={(value) => submit(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="course-section py-5 section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="row">
              <div className="col-md-3 text-center">
                <div className="card card-body">
                  {
                    <FilterSecondary
                      genre={selectedFiltersSecondary.genre}
                      langueEn={selectedFiltersSecondary.langueEn}
                      price={selectedFiltersSecondary.price}
                      onClick={(value) => saveDataFilterSecondary(value)}
                    />
                  }
                </div>
              </div>
              {isLoading.status ? (
                <div className="col-md-9 text-center">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <h5 className="mt-2">{isLoading.message}</h5>
                </div>
              ) : (
                <div className="col-md-9">
                  <div className="row justify-content-center  row-cols-1">
                    {prof?.map((val, i) => (
                      <div className="col pb-2" key={i}>
                        <div className="course-item">
                          <div className="course-inner">
                            <CardProvider
                              user={val}
                              matieres={selectedFilters.matiere}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mb-3">
                    {data && (
                      <ul className="default-pagination lab-ul">
                        {data?.current_page > 1 && (
                          <li>
                            <button
                              type="button"
                              onClick={() => PageFn(data.prev_page_url)}
                              className="btn"
                            >
                              <i className="icofont-rounded-left"></i>
                            </button>
                          </li>
                        )}
                        {data.links &&
                          data.links.map(
                            (val, i) =>
                              val.url &&
                              (val.label.match("&") ? false : true) && (
                                <li key={i}>
                                  <button
                                    type="button"
                                    onClick={() => PageFn(val.url)}
                                    className={
                                      data?.current_page === val.label
                                        ? "btn btn-danger  rounded-pill"
                                        : "btn btn-primary rounded-pill"
                                    }
                                    style={{ width: "40px", height: "40px" }}
                                  >
                                    {val.label}
                                  </button>
                                </li>
                              )
                          )}
                        {data.current_page < data.last_page && (
                          <li>
                            <button
                              type="button"
                              onClick={() => PageFn(data.next_page_url)}
                              className="btn"
                            >
                              <i className="icofont-rounded-right"></i>
                            </button>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CoursePage;
